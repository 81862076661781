import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import FormFieldUser from 'app/components/form/formField/FormFieldUser'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppListItem,
  WppSelect,
  WppSideModal,
  WppTypography,
} from 'buildingBlocks'
import { ORDER_BY } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import IChangeLogFilter from 'interfaces/changeLog/IChangeLogFilter'
import IChangeLogSelectedFilter from 'interfaces/changeLog/IChangeLogSelectedFilter'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import styles from 'pages/changeLog/filter/Filter.module.scss'
import { AppDispatch, RootState } from 'store'
import IChangeLogState from 'store/interfaces/IChangeLogState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'
import {
  clearFilter,
  setFilterData,
  setFilterFormFields,
  setOpenFilterModal,
  setSelectedFilterData,
} from 'store/reducers/changeLogSlice'
import FilterHelper from 'utils/changeLog/FilterHelper'
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types'

interface ISideModalFilterProps {
  handleFilterChange: (filter: IChangeLogFilter, orderBy: ORDER_BY) => void
}

/**
 * Change Log Side Filter modal Component
 */
const SideModalFilter: React.FC<ISideModalFilterProps> = ({
  handleFilterChange,
}: ISideModalFilterProps): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const formFieldRef = useRef<HTMLWppSelectElement>(null)
  const { osApi } = useOs()
  const { t } = useTranslation()
  const { openFilterModal, sideModalData, filterSelectedData, tableData } = useSelector<RootState, IChangeLogState>(
    (state: RootState) => state.changeLogState,
  )

  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)

  const [formData, setFormData] = useState<IChangeLogSelectedFilter>(filterSelectedData.sideModal)

  const setFilterModal = (open: boolean) => {
    dispatch(setOpenFilterModal(open))
  }

  useEffect(() => {
    if (questionnaireState.app) {
      dispatch(setFilterData(questionnaireState.app))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireState.app])

  useEffect(() => {
    if (openFilterModal) setFormData(filterSelectedData.sideModal)
  }, [filterSelectedData.sideModal, openFilterModal])

  const userFieldConfig: IField = {
    ...formData.selectedUserField,
    fieldConfig: { ...formData.selectedUserField.fieldConfig, accessToken: osApi.getAccessToken() },
  }

  const handleClearAll = (): void => {
    dispatch(clearFilter())
  }

  return (
    <>
      <WppFilterButton
        onClick={() => setFilterModal(true)}
        counter={FilterHelper.getFilterCount(sideModalData, filterSelectedData)}
      >
        <Trans>app.button.filter</Trans>
      </WppFilterButton>
      {createPortal(
        <WppSideModal
          data-testid="chg-log-side-filter-modal"
          size="s"
          open={openFilterModal}
          onWppSideModalClose={() => setFilterModal(false)}
          onWppSideModalOpen={() => setFilterModal(true)}
          disableOutsideClick={true}
        >
          <h3 slot="header">
            <Trans>changeLog.filter.headerTitle</Trans>
          </h3>
          <div slot="body" className={styles.sideModalBody}>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.categories</Trans>
              </WppTypography>
              <WppSelect
                data-testid="category-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  const formFields: Array<IField> = FilterHelper.getFieldsByCategories(event.detail.value)
                  dispatch(setFilterFormFields(formFields))
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    selectedCategories: event.detail.value,
                    selectedFormFields: formFields,
                  }))
                  formFieldRef?.current?.updateOptions()
                }}
                placeholder={t('changeLog.filter.placeholder.categories')}
                value={formData.selectedCategories}
                withSearch
                withFolder
                type="multiple"
              >
                {sideModalData.categories.map((category: ICategory) => (
                  <WppListItem value={category} key={category.id}>
                    <p slot="label">{category.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.formFields</Trans>
              </WppTypography>
              <WppSelect
                ref={formFieldRef}
                data-testid="form-field-change"
                onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    selectedFormFields: event.detail.value,
                  }))
                }}
                disabled={_.isEmpty(formData.selectedCategories)}
                placeholder={t('changeLog.filter.placeholder.formFields')}
                value={formData.selectedFormFields}
                withSearch
                withFolder
                type="multiple"
              >
                {sideModalData.formFields.map((field: IField) => (
                  <WppListItem value={field} key={field.id}>
                    <p slot="label">{field.fieldConfig.text}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
            <div className={styles.fieldContainer}>
              <WppTypography type="s-strong">
                <Trans>changeLog.filter.label.userNames</Trans>
              </WppTypography>
              <FormFieldUser
                data-testid="user-field-change"
                field={userFieldConfig}
                handleChange={(event: IFieldChangeEvent) => {
                  setFormData((prevState: IChangeLogSelectedFilter) => ({
                    ...prevState,
                    selectedUserField: {
                      ...prevState.selectedUserField,
                      value: event.value,
                    },
                  }))
                }}
              />
            </div>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppActionButton onClick={handleClearAll} data-testid="btn-chg-log-filter-clearAll" variant="secondary">
              <Trans>app.button.clearAll</Trans>
            </WppActionButton>
            <div className={styles.btnActionContainer}>
              <WppButton
                data-testid="btn-chg-log-filter-cancel"
                variant="secondary"
                onClick={() => setFilterModal(false)}
              >
                <Trans>app.button.cancel</Trans>
              </WppButton>
              <WppButton
                data-testid="btn-chg-log-filter-apply"
                onClick={() => {
                  dispatch(setSelectedFilterData(formData))
                  handleFilterChange(
                    {
                      ...filterSelectedData,
                      sideModal: formData,
                    },
                    tableData.orderBy,
                  )
                }}
                disabled={_.isEmpty(formData.selectedCategories) || _.isEmpty(formData.selectedFormFields)}
              >
                <Trans>app.button.apply</Trans>
              </WppButton>
            </div>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SideModalFilter
