// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dyUPl{max-height:300px;overflow:auto;display:flex;flex-direction:column-reverse}.dyUPl .FJd30{display:flex;flex-direction:column-reverse;gap:24px;overflow:hidden !important}.dyUPl::-webkit-scrollbar{width:10px}.dyUPl::-webkit-scrollbar-track{background-color:#f1f1f1}.dyUPl::-webkit-scrollbar-thumb{background-color:var(--wpp-grey-color-400)}.dyUPl .FID8o{display:flex;flex-direction:column;gap:12px}.dyUPl .FID8o .nu82v{overflow-wrap:anywhere}.dyUPl .FID8o .eyB_6{display:flex;gap:8px;align-items:center;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldComment/formFieldCommentDetail/FormFieldCommentDetail.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CAEA,cACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,0BAAA,CAGF,0BACE,UAAA,CAGF,gCACE,wBAAA,CAGF,gCACE,0CAAA,CAGF,cACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,qBACE,sBAAA,CAGF,qBACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".rootContainer {\n  max-height: 300px;\n  overflow: auto;\n  display: flex;\n  flex-direction: column-reverse;\n\n  .infiniteScrollContainer {\n    display: flex;\n    flex-direction: column-reverse;\n    gap: 24px;\n    overflow: hidden !important;\n  }\n\n  &::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: #f1f1f1;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--wpp-grey-color-400);\n  }\n\n  .commentContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    .commentDetail {\n      overflow-wrap: anywhere;\n    }\n\n    .userInfo {\n      display: flex;\n      gap: 8px;\n      align-items: center;\n      flex-wrap: wrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootContainer": `dyUPl`,
	"infiniteScrollContainer": `FJd30`,
	"commentContainer": `FID8o`,
	"commentDetail": `nu82v`,
	"userInfo": `eyB_6`
};
export default ___CSS_LOADER_EXPORT___;
